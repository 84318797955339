/* contact.scss */

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    background-color: #f0f0f0;
        

    @media (max-width: 768px) {
        height: 64vh;
        }
    

    
    .contact__card {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 500px;
      padding: 20px;
      
      .contact__header {
        text-align: center;
        margin-bottom: 20px;
        
        .contact__avatar {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 10px;
        }
        
        .contact__name {
          font-size: 1.5rem;
          color: #333;
          margin-bottom: 5px;
        }
        
        .contact__headline {
          font-size: 1rem;
          color: #666;
        }
      }
      
      .contact__body {
        .contact__bio {
          font-size: 0.875rem;
          color: #444;
          margin-bottom: 15px;
        }
        
        .contact__cta {
          text-align: center;
          
          .contact__button {
            display: inline-block;
            padding: 10px 20px;
            background-color: #0077B5;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            
            &:hover {
              background-color: #004C7D;
            }
          }
        }
      }
    }
  }
  