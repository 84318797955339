.SliderContainer {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.ImgSlider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  opacity: 1;
  transition: transform .65s ease-out;
  overflow: hidden;
  background-color: black;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0.4rem);
  }

  .ProjectTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
    z-index: 2;
    text-align: center;

    @media (max-width: 1200px) {
      font-size: 4rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
  }
  }

  &.incoming {
    transform: translateX(100%);
  }

  &.active {
    z-index: 1;
  }
}

.Dots {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;

  .Dot {
    width: 13px;
    height: 10px;
    display: inline-block;
    background: #fff;
    opacity: 0.2;
    margin: 0 5px;
    border-radius: 20px;
    cursor: pointer;
    transition: opacity 0.5s, background-color 0.5s, width 0.5s;
    transition-delay: 0.5s, 0.5s, 0s;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
      background: #ffffff;
      width: 100px;
      transition-delay: 0s;
    }
  }
}

.Arrow {
  position: absolute;
  top: 40%;
  cursor: pointer;
  fill: white;
  z-index: 2;
  transition: transform 0.3s ease;


  &.ArrowSliderRight {
    right: 20px;
    &:active {
      transform: scale(0.8);
    }

    @media (max-width: 768px) {
      transform: scale(0.5);
    }
  }

  &.ArrowSliderLeft {
    left: 20px;
    transform: scaleX(-1);
    &:active {
      transform: scaleX(-1) scale(0.8);
    }

    @media (max-width: 768px) {
      transform: scaleX(-1) scale(0.5);
    }
  }
}
