.tech-stack {
    display: flex;
    gap: .5rem;
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.github-badge {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    gap: 4rem;

    & > a {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        cursor: pointer;
    }
}



.description, .description_2 {
    margin-top: 5rem;
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    justify-content: center;

    &__text {
        font-size: 1.3rem;
        overflow: hidden;
        text-overflow: 
        ellipsis;
        width: 30%;
        align-self: center;

        @media (max-width: 1200px) {
            width: 75%;
          }

        @media (max-width: 768px) {
        width: 90%;
        font-size: 1rem;
        }

    }

    &__image {
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: transform 1s ease;
        width: 40%;
        max-width: 500px;
        max-height: 500px;

        @media (max-width: 1200px) {
            width: 80%;
            max-width: 800px;
          }

          @media (max-width: 768px) {
            width: 90%;
            max-width: 400px;
            min-width: 300px;
        }


        &:hover {
            transform: scale(0.95);
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
      }
}
