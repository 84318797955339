.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px); 
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .modal-content img {
    width: 100%;
    height: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #e74c3c; /* Red background */
    width: 28px;
    height: 28px;
    border-radius: 50%; /* Circular shape */
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .close-button:hover {
    background-color: #c0392b; /* Darker red background on hover */
    transform: scale(1.1);
  }
  
  .close-icon {
    font-size: 20px; /* Larger cross size */
    color: white; /* White color for contrast */
    font-weight: bold; /* Make the cross thicker */
    transform: rotate(45deg); /* Rotate cross to form an X */
  }
  