

.header {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
    z-index: 3;
    background-color: white;
    position: sticky;
    top: 0;

    @media (max-width: 768px) {
        position: inherit;
        justify-content: space-around;
    }



    &__logo {
        border: black 2px solid;
        border-radius: 50%;
        height: 2.5rem;
        aspect-ratio: 1/1;
    }

    &__nav, .nav__list {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }

    .list__item {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .last-commit-info {
        @media (max-width: 768px) {
            display: none;
        }
    }
}
